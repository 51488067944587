//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "vue";
import MixinTeamOptions from "@/mixins/teamOptions";
import MixinWebrtc from "@/mixins/webrtcNew";
import Request from "@/webrtc/server/internal/Request";
export default {
  name: "ChatHistory",
  components: {
    Header: () => import("@/components/Header"),
    selMember: () => import("@/components/Selectors/MemberSelectCopy"),
    ChartPie: () => import("@/components/Charts/Pie"),
    ChartColumn: () => import("@/components/Charts/Column"),
    ChatHistoryDialog: () => import("@/components/Dialogs/chatHistoryDialog"),
    ChartMore: () => import("@/components/keyWordChartMore"),
    SearchDrawer: () => import("@/components/SearchDrawer"),
  },
  mixins: [MixinTeamOptions, MixinWebrtc],
  data() {
    return {
      functionName: "聊天记录",
      NeedDataChannel: true,
      dialogTytle: null,
      dialogs: {
        browser: {
          open: false,
          options: {},
        },
      },
      chartLoading: false,
      chartData: null,
      chatSoftware: null, //聊天软件类型
      // searchType: 1, //搜索类型
      searchTitle: null,
      searchContent: null, //搜索内容
      ds: null, //开始时间
      de: null, //结束时间
      tableData: [],
      pageData: {
        pageIndex: 1,
        pageSize: 20,
        totalNum: 0,
      }, //分页
      loading: false,
      teamOptions: [], //团队选择器
      teamValue: null, //选择的团队
      dateType: 1, //时间查询类型
      selDate: new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate() + 1
      ),
      pickOption: this.pickDate(),
      DateRange: [
        new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate() - 7
        ),
        new Date(),
      ],
      dateRange2PickerOptions: {
        disabledDate: (v) => v <= new Date(this.DateRange[0]),
      },
      selMem: [],
      moreChartOption: {
        title: null,
        tData: [],
        mName: "chathChartMore",
        type: 1, //1为停留时长，2为次数
        chartType: null, //点击的图表类型
        dataTotal: 0,
      },
      keyWordTags: {
        contentTag: [],
        titleTag: [],
      },
      selKeyWordTags: {
        contentTagSel: [],
        titleTagSel: [],
      },
    };
  },
  computed: {
    previewSrcList() {
      return this.tableData.map(({ ImageName }) =>
        this.handleImageSrc(ImageName)
      );
    },
  },
  methods: {
    sendWebrtcRequestNoIdentitySync(request, successCallback, failCallback) {
      this.sendWebrtcRequestNoIdentity(request)
        .then((resp) => successCallback?.(resp))
        .catch((error) => {
          failCallback?.(error);
        });
    },
    async sendWebrtcRequestNoIdentity(request) {
      if (this.isLocalStorage) {
        var identity = this.selMem[0].Identity;
        await this.Call(identity);
        var b = this.SendRequest(null, identity, request);
        if (!b) {
          throw "发送请求失败";
        }
      }
    },
    /**
     * 选择标签
     */
    handleSelTag(type, val) {
      if (type == 1) {
        if (this.selKeyWordTags.titleTagSel.findIndex((m) => m == val) == -1) {
          this.selKeyWordTags.titleTagSel.push(val);
        } else {
          this.selKeyWordTags.titleTagSel =
            this.selKeyWordTags.titleTagSel.filter((s) => s != val);
        }
      } else {
        if (
          this.selKeyWordTags.contentTagSel.findIndex((m) => m == val) == -1
        ) {
          this.selKeyWordTags.contentTagSel.push(val);
        } else {
          this.selKeyWordTags.contentTagSel =
            this.selKeyWordTags.contentTagSel.filter((s) => s != val);
        }
      }
    },
    /**
     * 获取关键词标签
     */
    getKeyWordTag() {
      let data = {
        startTime: this.ds,
        endTime: this.de,
        users: this.selMem.map((m) => m.UsId),
        teamId: this.teamValue,
        title: this.searchTitle ? [this.searchTitle] : [],
        content: this.searchContent ? [this.searchContent] : [],
        chatTypes: this.chatSoftware,
        chartType: "chatKeywordsRank", //titleKeywordsRank,browserTypesRank,siteRank
        page: 1,
        limit: 20,
      };
      let data1 = {
        startTime: this.ds,
        endTime: this.de,
        users: this.selMem.map((m) => m.UsId),
        teamId: this.teamValue,
        processName: [],
        softwareName: [],
        formName: this.focusFormNameText ? [this.focusFormNameText] : [],
        chartType: "formKeywordsRank", //titleKeywordsRank,browserTypesRank,siteRank
        page: 1,
        limit: 20,
      };
      if (this.isLocalStorage) {
        var self = this;
        var promiseChat = new Promise((resolve, reject) => {
          var request = new Request();
          request.Url = "/Process/ChatDetail";
          request.ContentType = "Json";
          const token = this.$xStorage.getItem("token");
          request.Headers.Authorization = token;
          request.Body = JSON.stringify(data);
          request.OnError = function (error) {
            reject(error);
          };
          request.OnResponse = function (response) {
            let result = response.Result;
            if (!result.State) {
              reject(result.Message);
            } else {
              resolve(result);
            }
          };
          this.sendWebrtcRequestNoIdentity(request).catch((error) => {
            reject(error);
          });
        });
        var promiseProcess = new Promise((resolve, reject) => {
          var request = new Request();
          request.Url = "/Process/ProcessDetail";
          request.ContentType = "Json";
          const token = this.$xStorage.getItem("token");
          request.Headers.Authorization = token;
          request.Body = JSON.stringify(data1);
          request.OnError = function (error) {
            reject(error);
          };
          request.OnResponse = function (response) {
            let result = response.Result;
            if (!result.State) {
              reject(result.Message);
            } else {
              resolve(result);
            }
          };
          this.sendWebrtcRequestNoIdentity(request).catch((error) => {
            reject(error);
          });
        });
        Promise.all([promiseChat, promiseProcess]).then((resp) => {
          if (resp[0].State) {
            self.keyWordTags.contentTag = resp[0].Data.Datas;
          }
          if (resp[1].State) {
            self.keyWordTags.titleTag = resp[1].Data.Datas;
          }
        });
      } else {
        Promise.all([
          this.$http.post("/Monitors/Details/Chat.ashx", data),
          this.$http.post("/Monitors/Details/Process.ashx", data1),
        ]).then((resp) => {
          if (resp[0].res == 0) {
            this.keyWordTags.contentTag = resp[0].data.Datas;
          }
          if (resp[1].res == 0) {
            this.keyWordTags.titleTag = resp[1].data.Datas;
          }
        });
      }
    },
    /**
     * 图表查看更多
     */
    handleChartMore(type) {
      this.moreChartOption.chartType = type;
      if (type == 1) {
        this.moreChartOption.type = 1;
        this.moreChartOption.title = "聊天类型";
      } else if (type == 2) {
        this.moreChartOption.type = 2;
        this.moreChartOption.title = "聊天内容关键字";
      }
      this.$modal.show("chathChartMore");
    },
    /**
     * 获取图表更多数据
     */
    getMoreChartData(page = 1, limit = 20, type) {
      this.$refs.chartMore.loadingChange(true);
      let chartType = "";
      if (type == 1) {
        chartType = "chatTypesRank";
      } else if (type == 2) {
        chartType = "chatKeywordsRank";
      }
      let data = {
        startTime: this.ds,
        endTime: this.de,
        users: this.selMem.map((m) => m.UsId),
        teamId: this.teamValue,
        title: this.searchTitle ? [this.searchTitle] : [],
        content: this.searchContent ? [this.searchContent] : [],
        chatTypes: this.chatSoftware,
        chartType: chartType, //titleKeywordsRank,browserTypesRank,siteRank
        page: page,
        limit: limit,
      };
      if (
        this.selKeyWordTags.titleTagSel &&
        this.selKeyWordTags.titleTagSel.length
      ) {
        data.title = this.selKeyWordTags.titleTagSel;
      }
      if (
        this.selKeyWordTags.contentTagSel &&
        this.selKeyWordTags.contentTagSel.length
      ) {
        data.content = this.selKeyWordTags.contentTagSel;
      }
      if (this.isLocalStorage) {
        var self = this;
        var request = new Request();
        request.Url = "/Process/ChatDetail";
        request.ContentType = "Json";
        const token = this.$xStorage.getItem("token");
        request.Headers.Authorization = token;
        request.Body = JSON.stringify(data);
        request.OnError = function () {
          self.$refs.chartMore.loadingChange(false);
        };
        request.OnResponse = function (response) {
          let result = response.Result;
          if (result.State) {
            if (type == 1) {
              result.Data.Datas.forEach((m) => {
                m.name = m.Browser;
              });
            } else if (type == 2) {
              result.Data.Datas.forEach((m) => {
                m.name = m.Keyword;
              });
            }
            self.moreChartOption.tData = result.Data.Datas;
            self.moreChartOption.dataTotal = result.Data.Total;
          }
          self.$refs.chartMore.loadingChange(false);
        };
        this.sendWebrtcRequestNoIdentity(request).catch((error) => {
          self.$refs.chartMore.loadingChange(false);
        });
      } else {
        this.$http
          .post("/Monitors/Details/Chat.ashx", data)
          .then((resp) => {
            if (resp.res == 0) {
              if (type == 1) {
                resp.data.Datas.forEach((m) => {
                  m.name = m.Browser;
                });
              } else if (type == 2) {
                resp.data.Datas.forEach((m) => {
                  m.name = m.Keyword;
                });
              }
              this.moreChartOption.tData = resp.data.Datas;
              this.moreChartOption.dataTotal = resp.data.Total;
            }
          })
          .finally(() => this.$refs.chartMore.loadingChange(false));
      }
    },
    /**
     * 点击图表查看详情
     * 1:聊天类型  2:聊天内容关键字
     */
    handleElementClick(val, type) {
      let chatSoftwareData = this.chatSoftware;
      let searchContentData = this.searchContent ? [this.searchContent] : [];
      if (
        this.selKeyWordTags.contentTagSel &&
        this.selKeyWordTags.contentTagSel.length
      ) {
        searchContentData = this.selKeyWordTags.contentTagSel;
      }
      if (type == 1) {
        this.dialogTytle = `聊天类型记录"${val}"`;
        chatSoftwareData = [this.$D.VALUE("chat_software", val)];
      } else if (type == 2) {
        this.dialogTytle = `聊天内容关键字"${val}"`;
        searchContentData = [val];
      }
      this.dialogs.browser = {
        open: true,
        options: {
          startTime: this.ds,
          endTime: this.de,
          users: this.selMem.map((m) => m.UsId),
          teamId: this.teamValue,
          title: this.selKeyWordTags.titleTagSel.length
            ? this.selKeyWordTags.titleTagSel
            : this.searchTitle
            ? [this.searchTitle]
            : [],
          content: searchContentData,
          datetype: this.dateType,
          chatTypes: chatSoftwareData,
        },
      };
    },
    /**
     * 获取列表数据
     */
    async handleGetData() {
      if (!this.teamValue) {
        this.$message({
          message: "请先选择团队",
          type: "warning",
        });
        return;
      }
      if (this.isLocalStorage && this.selMem.length == 0) {
        this.$message({
          message: "请先选择成员",
          type: "warning",
        });
        return;
      }
      // this.pageData.pageIndex = 1;
      // this.tableData = [];
      this.loading = true;
      this.HangUpAll();
      if (this.isLocalStorage) {
        var identity = this.selMem[0].Identity;
        try {
          await this.Call(identity);
        } catch (error) {}
      }
      this.queryData();
      this.getChartData();
      this.getKeyWordTag();
    },
    initDate() {
      const day = new Date(this.selDate).getDay();
      const dayNum = day > 0 ? day - 1 : 6;
      const date = new Date(this.selDate);
      if (this.dateType == 1) {
        this.ds = new Date(date.setDate(date.getDate() - dayNum)).timeFormat(
          "yyyy-MM-dd 00:00:01"
        );
        const s = new Date(this.ds);
        this.de = new Date(s.setDate(s.getDate() + 6)).timeFormat(
          "yyyy-MM-dd 23:59:59"
        );
      } else if (this.dateType == 2) {
        this.ds = new Date(date.getFullYear(), date.getMonth(), 1).timeFormat(
          "yyyy-MM-dd 00:00:01"
        );
        this.de = new Date(
          date.getFullYear(),
          date.getMonth() + 1,
          0
        ).timeFormat("yyyy-MM-dd 23:59:59");
      } else {
        this.ds = this.DateRange[0].timeFormat("yyyy-MM-dd 00:00:01");
        this.de = this.DateRange[1].timeFormat("yyyy-MM-dd 23:59:59");
      }
      {
        const _ = new Date(this.de);
        const date = _.getDate();
        _.setDate(date + 1);
        this.de = _.timeFormat("yyyy-MM-dd 23:59:59");
      }
    },
    /**
     * 获取图表数据
     */
    getChartData() {
      this.chartLoading = true;
      let data = {
        startTime: this.ds,
        endTime: this.de,
        users: this.selMem.map((m) => m.UsId),
        teamId: this.teamValue,
        title: this.searchTitle ? [this.searchTitle] : [],
        content: this.searchContent ? [this.searchContent] : [],
        datetype: this.dateType,
        chatTypes: this.chatSoftware,
      };
      if (
        this.selKeyWordTags.titleTagSel &&
        this.selKeyWordTags.titleTagSel.length
      ) {
        data.title = this.selKeyWordTags.titleTagSel;
      }
      if (
        this.selKeyWordTags.contentTagSel &&
        this.selKeyWordTags.contentTagSel.length
      ) {
        data.content = this.selKeyWordTags.contentTagSel;
      }
      if (this.isLocalStorage) {
        var self = this;
        var request = new Request();
        request.Url = "/Process/ChatChart";
        request.ContentType = "Json";
        const token = this.$xStorage.getItem("token");
        request.Headers.Authorization = token;
        request.Body = JSON.stringify(data);
        request.OnError = function () {
          self.chartLoading = false;
        };
        request.OnResponse = function (response) {
          let result = response.Result;
          if (result.State) {
            self.chartData = result.Data;
          }
          self.chartLoading = false;
        };
        this.sendWebrtcRequestNoIdentity(request).catch((error) => {
          this.chartLoading = false;
        });
      } else {
        this.$http
          .post("/Monitors/Charts/Chat.ashx", data)
          .then((resp) => {
            if (resp.res == 0) {
              this.chartData = resp.data;
            }
          })
          .finally(() => (this.chartLoading = false));
      }
    },
    async queryData() {
      this.loading = true;
      this.initDate();
      let data = {
        startTime: this.ds,
        endTime: this.de,
        users: this.selMem.map((m) => m.UsId),
        page: this.pageData.pageIndex,
        limit: this.pageData.pageSize,
        teamId: this.teamValue,
        title: this.searchTitle ? [this.searchTitle] : [],
        content: this.searchContent ? [this.searchContent] : [],
        datetype: this.dateType,
        chatTypes: this.chatSoftware,
      };
      if (
        this.selKeyWordTags.titleTagSel &&
        this.selKeyWordTags.titleTagSel.length
      ) {
        data.title = this.selKeyWordTags.titleTagSel;
      }
      if (
        this.selKeyWordTags.contentTagSel &&
        this.selKeyWordTags.contentTagSel.length
      ) {
        data.content = this.selKeyWordTags.contentTagSel;
      }
      if (this.isLocalStorage) {
        var self = this;
        var request = new Request();
        request.Url = "/Process/Chat";
        request.ContentType = "Json";
        const token = this.$xStorage.getItem("token");
        request.Headers.Authorization = token;
        request.Body = JSON.stringify(data);
        request.OnError = function (error) {
          self.$message({
            type: "error",
            message: error,
          });
          self.loading = false;
        };
        request.OnResponse = function (response) {
          let result = response.Result;
          if (!result.State) {
            self.$message({
              type: "error",
              message: result.Message,
            });
          } else {
            self.tableData = result.Data.Datas;
            self.pageData.totalNum = result.Data.Total;
          }
          self.loading = false;
        };
        this.sendWebrtcRequestNoIdentity(request).catch((error) => {
          this.$message({
            type: "error",
            message: error,
          });
          this.loading = false;
        });
      } else {
        const { res, data: { Datas, Total } = {} } = await this.$http.post(
          "/Monitors/Chat.ashx",
          data
        );
        if (res !== 0) return;

        this.tableData = Datas;
        this.pageData.totalNum = Total;
        this.loading = false;
      }
    },
    async handleSizeChange(val) {
      this.pageData.pageSize = val;
      if (!this.teamValue) {
        this.$message({
          message: "请先选择团队",
          type: "warning",
        });
        return;
      }
      if (this.isLocalStorage && this.selMem.length == 0) {
        this.$message({
          message: "请先选择成员",
          type: "warning",
        });
        return;
      }
      if (this.isLocalStorage) {
        var identity = this.selMem[0].Identity;
        try {
          await this.Call(identity);
        } catch (error) {
          console.log(error);
        }
      }
      this.queryData();
    },
    async handleCurrentChange(val) {
      this.pageData.pageIndex = val;
      if (!this.teamValue) {
        this.$message({
          message: "请先选择团队",
          type: "warning",
        });
        return;
      }
      if (this.isLocalStorage && this.selMem.length == 0) {
        this.$message({
          message: "请先选择成员",
          type: "warning",
        });
        return;
      }
      if (this.isLocalStorage) {
        var identity = this.selMem[0].Identity;
        try {
          await this.Call(identity);
        } catch (error) {
          console.log(error);
        }
      }
      this.queryData();
    },
    pickDate() {
      return {
        disabledDate(time) {
          return time.getTime() > new Date().getTime();
        },
      };
    },
    /**
     * 选择成员
     */
    getSelMember(arr) {
      this.selMem = arr;
    },
    /**
     * 团队切换
     */
    handleTeamChange() {
      this.selMem = [];
    },
    attenceHeaderStyle() {
      return {
        color: "#909399",
      };
    },
  },
  beforeDestroy() {
    this.HangUpAll();
  },
};
